

const HeroSection = () => {
  return (
    <div className="relative w-full">
      <img
        src="https://saumicbucket.s3.ap-south-1.amazonaws.com/homepage+folder/how/ban.webp"
        alt="Why"
        className="w-full h-full object-cover"
      />

    

      
    </div>
  );
};

export default HeroSection;
